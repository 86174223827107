import React from "react";
import { Container, Row, Col } from "reactstrap";
import courseImg01 from '../../assets/images/web-development.png';
import courseImg02 from '../../assets/images/kids-learning.png';
import courseImg03 from '../../assets/images/seo.png';
import courseImg04 from '../../assets/images/ui-ux.png';

import FreeCourseCard from "./FreeCourseCard";

import "./FreeCourse.css";

const freeCourseData = [
    {
        id: "01",
        title: "175181728",
        imgUrl: courseImg01,
        students: 5.3,
        rating: 1.7,
    },
    {
        id: "02",
        title: "mdscknbskbcks",
        imgUrl: courseImg02,
        students: 5.3,
        rating: 1.7,
    },

    {
        id: "03",
        title: "kdfdihwihiu",
        imgUrl: courseImg03,
        students: 5.3,
        rating: 1.7,
    },

    {
        id: "04",
        title: "ldhfjwehjkh",
        imgUrl: courseImg04,
        students: 5.3,
        rating: 1.7,
    },
];

const FreeCourse = () => {
    return (
        <section>
            <Container>
                <Row>
                    <Col lg="12" className="text-center mb-5">
                        <h2 className="fw-bold">Our Free Plan's</h2>
                    </Col>

                    {freeCourseData.map((item) => (
                        <Col lg="3" md="4" className="mb-4" key={item.id}>
                            <FreeCourseCard item={item} />
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
};

export default FreeCourse;
