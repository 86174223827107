import React, { Fragment } from "react";

import Header from "../components/Header/Header";
import HeroSection from "../components/Hero-section/HeroSection";
import CompanySec from "../components/Company-section/CompanySec";

import AboutUs from "../components/About-us/AboutUs";
import Course from "../components/Course-section/Course";
import ChooseUs from "../components/Choose-us/Choose";

import Feature from "../components/Feature-section/Feature";
import FreeCourse from "../components/Free-course-sec/FreeCourse";
import Testimonial from "../components/Testimonial/Testimonial";

import NewsLetter from "../components/News-letter/NewsLetter";
import Footer from "../components/Footer/Footer";



const Home = () => {
    return (
        <Fragment>
            <Header />
            <HeroSection />
            <CompanySec />
            <AboutUs />
            <Course />
            <ChooseUs />
            <Feature />
            <FreeCourse />
            <Testimonial />
            <NewsLetter />
            <Footer />
        </Fragment>
    );
};

export default Home;
